* {
  margin: 0;
}



.home-gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1280px) {
  .home-gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .home-gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
  }
}
.home-gallery a {
  color: #fff;
  text-decoration: none;
}


.intro-landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 425px; */
  align-items: center;
  margin-bottom: 100px;
}

.intro-landing div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -100px;
  font-size: 3rem;
  width: 75%;
}

.text-container {
  height: 100px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 125px;
}

.text-container h1 {
  font-size: 50px;
  color: #e3e0e0;
}

.App-header {
  margin: 0;
  text-align: center;
}

@keyframes animate {
  50% {
    background-position: center;
  }
}
@media screen and (max-width: 450px) {
  .category-image__image {
    margin: 5px;
  }
  .intro-landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 425px; */
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .intro-landing div {
    font-size: 2rem;
    width: 75%;
    margin-top: 0;
  }
}/*# sourceMappingURL=home-gallery.css.map */
