.flip-box {
    background-color: transparent;
    width: 400px;
    height: 500px;
    border: none;
    perspective: 1000px;
    // transform: skew(-5deg);
  }
  
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-box-back {
    background-color:#181818;
    color: white;
    transform: rotateY(180deg);
  }

  @media screen and (max-width: 450px) {

  .flip-box{
    width: 80%;
  }

}