.mobile-navigation {
  display: flex;
  flex-direction: column;
  width: 0;
  height: 100vh;
  position: absolute;
  right: 0;
  background-color: #0F0F0F;
  top: 0;
  justify-content: center;
  z-index: 5;
  overflow-x: hidden;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.mobile-navigation a {
  font-size: 50px;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  /* Old browsers */
  background: linear-gradient(to right, transparent 50%, #ffffff 50%);
  background-size: 210% 100%;
  background-position: left bottom;
  transition: all 0.5s ease;
  text-transform: capitalize;
}

.mobile-navigation a:hover {
  transform: skew(-5deg);
  padding: 10px;
  background-position: right bottom;
  color: #0F0F0F;
}

.box-shadow-menu {
  position: fixed;
  padding-left: 1.25em;
  right: 15px;
  top: 10px;
  display: none;
  z-index: 6;
}

.box-shadow-menu:before {
  background: #fff;
  box-shadow: 0px 1em 0 0 #fff, 0 0.5em 0 0 #fff;
  content: "";
  height: 0.15em;
  left: 0;
  position: absolute;
  top: 0.25em;
  width: 100%;
}

.scale-up-menu {
  animation: expand 0.75s;
  animation-delay: 0.5s;
}

.scale-down-menu {
  animation: shrink 0.75s;
  animation-delay: 0.5s;
}

@keyframes expand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@media screen and (max-width: 450px) {
  .box-shadow-menu {
    display: block;
  }
}/*# sourceMappingURL=mobile-navbar.css.map */