.mobile-navigation{
  display: flex;
  flex-direction: column;
  width: 0;
  height: 100vh;
  position: absolute;
  right: 0;
  background-color: #0F0F0F;
  top: 0;
  justify-content: center;
  z-index: 5;
  overflow-x: hidden;
  transition: 0.5s;
  transition-delay: .5s;
}

.mobile-navigation a{
  font-size: 50px;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  /* Old browsers */
  background: linear-gradient(to right, transparent 50%, #ffffff 50%);
  background-size: 210% 100%;
  background-position:left bottom;
  transition:all .5s ease;
  text-transform: capitalize;
}

.mobile-navigation a:hover{
  transform: skew(-5deg);
  padding: 10px;
  background-position:right bottom;
  color:#0F0F0F;
}

.box-shadow-menu {
  position: fixed;
  padding-left: 1.25em;
  right: 15px;
  top: 10px;
  display: none;
  z-index: 6;
}
.box-shadow-menu:before {
  // content: "";
  // position: absolute;
  // left: 0;
  // top: 0.25em;
  // width: 1em;
  // height: 0.15em;
  // background: #fff;
  // box-shadow: 
  //   0 0.25em 0 0 #fff,
  //   0 0.5em 0 0 #fff;
    background: #fff;
    box-shadow: 0px 1em 0 0 #fff, 0 0.5em 0 0 #fff;
    content: "";
    height: 0.15em;
    left: 0;
    position: absolute;
    top: 0.25em;
    width: 100%;
}

// ---------------------- | Animation | -----------------------

.scale-up-menu {
  animation: expand .75s;
  animation-delay: .5s;
  // animation-fill-mode: forwards ;
}

.scale-down-menu {
  animation: shrink .75s;
  animation-delay: .5s;
  // animation-fill-mode: forwards ;
}

@keyframes expand{
  0% {
    // transform:scale(0);
    // right: -450px;
    width: 0;
  }
  100% {
    // transform:scale(1);
    // right: 0px;
    width: 100%;
  }
}

@keyframes shrink{
  0% {
    width: 100%;
    // transform:scale(1);
    // right: 0px;

  }
  100% {
    width: 0;
    // transform:scale(0);
    // right: -450px;
  }
}

// ---------------------- | Media | -----------------------

@media screen and (max-width: 450px) {
  .box-shadow-menu{
    display: block;
  }

}