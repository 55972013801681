h3.mc__title {
  margin-bottom: 40px;
  color: #fff;
}

form.mc__form {
  display: flex;
  justify-content: center;
  padding: 100px;
  width: 600px;
  background-color: #181818;
  border-radius: 0 100px 0 100px;
}

.mc__field-container {
  display: flex;
  flex-flow: column;
  width: 430px;
}

.form-input {
  margin: 10px;
  height: 40px;
  background: #d5cbcb;
}

.form-input::-moz-placeholder {
  color: #333;
  padding: 10px;
}

.form-input::placeholder {
  color: #333;
  padding: 10px;
}

#form-button {
  background-color: #8b3034;
  color: #fff;
  border: none;
  box-shadow: 13px 19px 20px rgba(11, 6, 6, 0.9019607843);
  cursor: pointer;
}

.newsletter-container {
  height: 100vh;
}

@media screen and (max-width: 450px) {
  form.mc__form {
    width: 90%;
    padding: 50px;
  }
}/*# sourceMappingURL=mailchimp-form.css.map */