.navigation {
  background-color: transparent;
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 45px;
}

.navigation a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  background: #ff3232;
  /* Old browsers */
  background: linear-gradient(to right, transparent 50%, #ffffff 50%);
  background-size: 210% 100%;
  background-position: left bottom;
  transition: all 0.5s ease;
  text-transform: capitalize;
}

.right a.nav-links:hover, .left a.nav-links:hover {
  display: inline-block;
  transition-duration: 0.5s;
  transform: skew(-5deg);
  padding: 10px;
  background-position: right bottom;
  color: #0F0F0F;
}

.navigation img {
    height: 100px;
    width: auto;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#logo {
  color: #fff;
}

@media screen and (max-width: 450px) {
  .navigation a:not(:first-of-type) {
    display: none;
  }
  .navigation .right {
    display: none;
  }
  .left {
    margin-top: 15px;
  }
}/*# sourceMappingURL=navbar.css.map */
