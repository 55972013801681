.category-image {
  position: relative;
  margin: 0 10px;
  transition: 0.35s ease-in-out;
  z-index: 0;
  flex-flow: column;
}

.category-image__image {
  z-index: 0;
  border-radius: 5px;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 250px;
  box-shadow: 0 5px 30px rgba(0,0,0,.9);
}

.category-image__text {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  text-transform: capitalize;
}

.category-image--hover {
  /* animation: expand 0.5s; */
  /* box-shadow: 5px 10px;
  transition: */
}

.category-image:hover {
  scale: 1.35;
  transform: skew(-5deg);
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.image-gallery {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: 0px;
  justify-content: center;
}

.image-gallery__thumb img {
  height: 400px;
  width: auto;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.9);
}

@media screen and (max-width: 450px) {
  .image-gallery {
    flex-flow: column;
  }
}/*# sourceMappingURL=image-content.css.map */