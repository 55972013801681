@use "./styles/partials/global" as *;
@use "./styles/partials/variables" as *;
@use "./styles/partials/mixins" as *;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Raleway&display=swap');
// @import url('./fonts/fonts.css');


* {
  box-sizing: border-box;
}
body {
  margin: 0;
  background-color: $dark;
  // font-family: 'formula-bold-regular', sans-serif;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}
.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-container{
  margin-top:100px;
  color: #fff;
}

.loading{
  background-color: $dark;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 9;
}
.loading .loading__load-ruler{
  animation: load-brand-animation 4s ease-in-out;
  height: 25vh;
  position: absolute;
  background-color: $light;
  top: 35%;
  z-index: 9;
}
.loading-text{
  position: absolute;
  top: 32%;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 25vh;
  color: #fff;
  // color: #0F0F0F;
  text-transform: uppercase;
  z-index: 9;
  height: 25vh;
}


@keyframes load-brand-animation {
  0%   {width:0%}
  100% {width:100%; }
}


// ------------------------- | ANIMATION  | -------------------------
// Back and fourth animation
.loading{
  display: block;
  background-color: $dark;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 7;
  top: 0;
}
.loading .loading__load-ruler{
  animation: loadanimation 6s ease-in-out;
  width: 100px;
  height: 40px;
  position: absolute;
  top: 0;
  background-color: $light;
  z-index: 8;
  transform: skew(-50deg);
}

.loading__load-ruler--2{
  animation: loadanimation-reverse 6s ease-in-out;
  width: 100px;
  height: 40px;
  position: absolute;
  background-color: $light;
  bottom: 0;
  right:0px;
  z-index: 8;
  transform: skew(-50deg);
}
@keyframes loadanimation-reverse {
  0% , 100%  {width:0}
  25% {width:100%; }
  50%  {width:0}
  75% {width:100%; }
}

@keyframes loadanimation {
  0% , 100%  {width:0}
  25% {width:100%; }
  50%  {width:0}
  75% {width:100%; }
}

// ------------------------- | MEDIA  | -------------------------
@media screen and (max-width: 450px) {

  .loading-text {
    left: 0;
    right: 0;
    font-size: 60px;
    color: #fff;
    text-transform: uppercase;
    z-index: 9;
    align-items: center;
    position: absolute;
    top: 50%;
    margin-top: -50px;
}
}