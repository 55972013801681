@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;

.home-gallery {
  // @extend .flex-standard;
  @include flex-column;
  @include desktop {
    @include flex-standard;
  }

  @include tablet {
    @include flex-standard;
  }
  a {
    color: #fff;
    text-decoration: none;
}
justify-content: center;

} /*# sourceMappingURL=gallery.css.map */

.intro-landing{
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 425px; */
  align-items: center;
  margin-bottom: 100px;
}

.intro-landing div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin-bottom: 100px;
  margin-top: -100px;
  font-size: 3rem;
  width: 75%;
}

// Home Gallery text animation ---------------->
.text-container {
  height: 100px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 125px;
}
.text-container h1{
  font-size: 50px;
  color: #e3e0e0;
}
.App-header{
  margin: 0;
  
  background: url("../../assets/paralysis.jpg");
  // top: -500px;
  background-repeat: repeat;
  // background-size: cover;
  // background-clip:text;
  animation: animate 60s ease-in-out infinite;
  text-align: center;
}

  @keyframes animate {
    50% {background-position: center;}
 
}

@media screen and (max-width: 450px) {
  .category-image__image {
    // height: 200px;
    margin: 5px;
  }
  .home-gallery{
    // margin-top: 100px;
  }
  .intro-landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 425px; */
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
}
  .intro-landing div{
    font-size: 2rem;
    width: 75%;
    margin-top: 0;
  }
    
}